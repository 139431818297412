import { Tooltip } from "react-tooltip";
import styles from "./styles.module.scss";
import { TooltipIcon } from "./TooltipIcon";

const AppTooltip = ({ id, content }) => {
  const tooltipContentStyles = {
    backgroundColor: "#ffffff",
    color: "#111111",
    padding: "15px",
    maxWidth: "400px",
    fontWeight: 400,
    fontSize: "14px",
  };

  return (
    <span className={styles.tooltipWrapperStyles} data-tooltip-id={id} data-tooltip-html={content}>
      <TooltipIcon />
      <Tooltip id={id} style={tooltipContentStyles} openOnClick={true} opacity={1} />
    </span>
  );
};

export default AppTooltip;
