import { addMember, deleteMember } from "../../../utils/members";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import styles from "./styles.module.scss";
import { CONTRACTS_BG } from "../index";
import AppTooltip from "../../ui/icons/Tooltip";

const TOOLTIP_CONTENT = {
  DEFAULT_ADMIN_ROLE:
    "Администраторы могут управлять списками пользователей, в том числе другими администраторами. Также только они могут ставить контракты на паузу.<br /><br />У каждого контракта свой список администраторов. Всегда должен оставаться хотя бы один администратор, иначе потеряется контроль над контрактом, поэтому в интерфейсе заблокировано удаление самого себя.",
  ACCOUNTANT_ROLE:
    "Аккаунтанты могут от имени кого угодно отправлять DMD кому угодно + создавать и сжигать DMD.<br /><br /> Эта роль необходима для работы контрактов Exchange и Game Transfer Bridge. Их адреса помечены в списке жёлтым и зелёным цветом, в соответствии с адресами контрактов.",
  COLLECTOR_ROLE:
    "Коллекторы могут забирать USDT из контракта Exchange.<br /><br />  Рекомендуется оставить в этом списке один кошелёк, на который будут выводиться средства.",
  MANAGER_ROLE:
    "Менеджеры могут устанавливать курс покупки, курс продажи DMD, а также блокировать продажу DMD.",
};

const MembersList = ({ members, dmdMembers }) => {
  const { title, list, getRoleMethod } = members;
  const dispatch = useDispatch();
  const wallet = useSelector(s => s.connect.wallet);
  const [newMember, setNewMember] = useState("");
  const [newMemberFieldError, setNewMemberFieldError] = useState("");

  const deleteMemberHandler = member => {
    dispatch(deleteMember({ member, role: getRoleMethod, dmdMembers }));
  };

  const addMemberHandler = () => {
    dispatch(
      addMember({
        setNewMember,
        setNewMemberFieldError,
        newMember,
        role: getRoleMethod,
        dmdMembers,
      }),
    );
  };

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>
        {title}
        <AppTooltip id="role-description" content={TOOLTIP_CONTENT[getRoleMethod]} />
      </h2>
      <div className={styles.inputWrapper}>
        <input
          type="text"
          value={newMember}
          onChange={e => {
            setNewMember(e.target.value);
            setNewMemberFieldError("");
          }}
        />
        <button onClick={addMemberHandler}>Add</button>
        <p className={styles.error}>{newMemberFieldError}</p>
      </div>
      <ul className={styles.list}>
        {list.map(member => {
          const hasDeleteButton =
            !(
              getRoleMethod === "DEFAULT_ADMIN_ROLE" &&
              member.toLowerCase() === wallet.toLowerCase()
            ) && !CONTRACTS_BG[member];

          return (
            <li key={member}>
              <span className={CONTRACTS_BG[member] || ""}>{member}</span>

              {hasDeleteButton && (
                <button onClick={() => deleteMemberHandler(member)}>Delete</button>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MembersList;
