import { getContract } from "./contract";
import { formatEther } from "ethers";
import { setDmdTokenTreasury } from "../store/reducers/membersSlice";

export const getTokenTreasury =
  ({ dmdMembers }) =>
  async (dispatch, getState) => {
    const provider = getState().connect.provider;
    const contract = await getContract({
      name: dmdMembers.dmdName,
      address: dmdMembers.dmdAddress,
      provider,
    });

    const formatter = new Intl.NumberFormat("fr-FR", {
      minimumFractionDigits: 1,
      maximumFractionDigits: 18,
    });

    const treasuryAmount = formatter.format(
      Number(formatEther(await contract.balanceOf(dmdMembers.dmdAddress))),
    );

    dispatch(setDmdTokenTreasury(treasuryAmount));
  };
