import DMDImgSrc from "../assets/images/DMD.png";
import TetherImgSrc from "../assets/images/tether.png";

export const DMD_TOKEN_DATA = {
  name: "DMD",
  decimals: 18,
  fullName: "Dominator",
  imgSrc: DMDImgSrc,
};

export const DMD_EXTERNAL_TOKEN_DATA = {
  name: "USDT",
  decimals: 18,
  fullName: "Tether",
  imgSrc: TetherImgSrc,
};
