import { getAddress } from "ethers";
import { getContract } from "./contract";
import { setLoaderData } from "../store/reducers/mainSlice";
import { membersActions } from "../store/reducers/membersSlice";

export const getMembers =
  ({ role, dmdMembers: { dmdName, dmdAddress, actionKey } }) =>
  async (dispatch, getState) => {
    const provider = getState().connect.provider;
    const contract = await getContract({
      name: dmdName,
      address: dmdAddress,
      provider,
    });

    const memberRole = await contract[role]();
    const numberOfMembers = await contract.getRoleMemberCount(memberRole);

    const membersList = [];

    for (let i = 0; i < numberOfMembers; i++) {
      const member = await contract.getRoleMember(memberRole, i);
      membersList.push(member);
    }

    const action = membersActions[actionKey];
    await dispatch(action({ role, list: membersList }));
  };

export const addMember =
  ({ setNewMember, setNewMemberFieldError, newMember, role, dmdMembers }) =>
  async (dispatch, getState) => {
    dispatch(setLoaderData({ isLoading: true, loadingText: "Adding" }));
    const provider = getState().connect.provider;
    const signer = await provider.getSigner();
    const contract = await getContract({
      name: dmdMembers.dmdName,
      address: dmdMembers.dmdAddress,
      signer,
    });
    const memberRole = await contract[role]();

    try {
      const tx = await contract.grantRole(memberRole, getAddress(newMember));
      await tx.wait();
      await dispatch(getMembers({ role, dmdMembers }));
      setNewMember("");
    } catch (e) {
      setNewMemberFieldError("Invalid address");
    } finally {
      dispatch(setLoaderData({ isLoading: false }));
    }
  };

export const deleteMember =
  ({ member, role, dmdMembers }) =>
  async (dispatch, getState) => {
    dispatch(setLoaderData({ isLoading: true, loadingText: "Removing" }));
    const provider = getState().connect.provider;
    const signer = await provider.getSigner();
    const contract = await getContract({
      name: dmdMembers.dmdName,
      address: dmdMembers.dmdAddress,
      signer,
    });
    const memberRole = await contract[role]();

    try {
      const tx = await contract.revokeRole(memberRole, getAddress(member));
      await tx.wait();
      await dispatch(getMembers({ role, dmdMembers }));
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setLoaderData({ isLoading: false }));
    }
  };
