import {
  BLOCK_EXPLORER_URL,
  CHAIN_ID,
  CHAIN_NAME,
  NATIVE_CURRENCY_NAME,
  NATIVE_CURRENCY_SYMBOL,
  RPC_URL,
} from "./api";

export const NETWORK_PARAMS = {
  rpcUrls: [RPC_URL],
  chainName: CHAIN_NAME,
  chainId: `0x${Number(CHAIN_ID).toString(16)}`,
  nativeCurrency: {
    name: NATIVE_CURRENCY_NAME,
    symbol: NATIVE_CURRENCY_SYMBOL,
    decimals: 18,
  },
  ...(BLOCK_EXPLORER_URL && {
    blockExplorerUrls: [BLOCK_EXPLORER_URL],
  }),
};

export const SWAP_RATE_MULTIPLIER = 1000000n;
export const NAVIGATION_STEPS = {
  connect: "CONNECT",
  operationSelect: "OPERATION_SELECT",
  swap: "SWAP",
  history: "HISTORY",
};
