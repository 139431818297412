import { setChainId, setWallet } from "../store/reducers/connectSlice";
import { setPlayerId } from "../store/reducers/userSlice";
import { setIsSellTokenEnabled, setSwapAddresses, setSwapRates } from "../store/reducers/swapSlice";
import { handleAttachWallet, handleDisconnect } from "./connect";
import { dmtClient } from "./axios";
import { getContract } from "./contract";
import { API_ROUTES, DMD_EXCHANGE_ADDRESS } from "../config/api";
import { setLoaderData, setMainError } from "../store/reducers/mainSlice";

export const getWallet = () => async (dispatch, getState) => {
  const provider = getState().connect.provider;
  const wallets = await provider.send("eth_requestAccounts");

  const userWallet = wallets[0];
  dispatch(setWallet(userWallet));
};

export const getInitialData = () => async (dispatch, getState) => {
  dispatch(setLoaderData({ isLoading: true }));

  try {
    await dispatch(getWallet());
    const { provider } = getState().connect;
    const chainId = await provider.send("eth_chainId");

    if (chainId) {
      dispatch(setChainId(chainId));
    }

    const exchangeContract = await getContract({
      name: "DMDExchange",
      address: DMD_EXCHANGE_ADDRESS,
      provider,
    });

    const isAccountPaused = await exchangeContract.paused();

    if (isAccountPaused) {
      throw Error("Contract is paused");
    }

    const dmdTokenAddress = await exchangeContract.dmdTokenAddress();
    const externalTokenAddress = await exchangeContract.externalTokenAddress();

    dispatch(
      setSwapAddresses({
        dmdTokenAddress,
        externalTokenAddress,
      }),
    );

    const buyRate = await exchangeContract.buyRate();
    const sellRate = await exchangeContract.sellRate();
    const isSellTokenEnabled = await exchangeContract.sellTokenEnabled();

    dispatch(setSwapRates({ buyRate, sellRate }));
    dispatch(setIsSellTokenEnabled(isSellTokenEnabled));
  } catch (error) {
    dispatch(setMainError({ message: error.error?.message || error.message }));

    throw error;
  } finally {
    dispatch(setLoaderData({ isLoading: false }));
  }
};

export const handleInitialActions = () => async (dispatch, getState) => {
  const { queryToken } = getState().main;
  const { wallet } = getState().connect;

  const authResponse = await dmtClient.post(API_ROUTES.auth, {
    token: queryToken,
  });
  const attachedWallet = authResponse.data.main_wallet;

  if (attachedWallet && attachedWallet !== wallet) {
    alert(`Wrong wallet! Please connect: ${attachedWallet}`);
    dispatch(handleDisconnect());

    throw Error();
  }

  dmtClient.defaults.headers.common["Authorization"] = `Bearer ${authResponse.data.token}`;
  const playerId = authResponse.data.player_id;
  dispatch(setPlayerId(playerId));

  if (!attachedWallet) {
    await dispatch(handleAttachWallet(playerId));
  }
};
