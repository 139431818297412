import { getContract } from "./contract";
import { DMD_EXCHANGE_ADDRESS, USDT_ADDRESS } from "../config/api";
import { formatEther, parseEther } from "ethers";
import { setLoaderData } from "../store/reducers/mainSlice";
import {
  setExchangerBalance,
  setPrivateBalance,
  setRates,
  setSellDMDEnabled,
} from "../store/reducers/exchangerSlice";
import { SWAP_RATE_MULTIPLIER } from "../config";

export const getBalance = () => async (dispatch, getState) => {
  const provider = getState().connect.provider;
  const wallet = getState().connect.wallet;
  const contract = await getContract({
    name: "Erc20",
    address: USDT_ADDRESS,
    provider,
  });

  const privateBalance = formatEther(await contract.balanceOf(wallet));
  const exchangerBalance = formatEther(await contract.balanceOf(DMD_EXCHANGE_ADDRESS));

  dispatch(setPrivateBalance(privateBalance));
  dispatch(setExchangerBalance(exchangerBalance));
};

export const collectDollars =
  ({ amount }) =>
  async (dispatch, getState) => {
    dispatch(setLoaderData({ isLoading: true, loadingText: "Collecting" }));

    const provider = getState().connect.provider;
    const signer = await provider.getSigner();
    const contract = await getContract({
      name: "DMDExchange",
      address: DMD_EXCHANGE_ADDRESS,
      signer,
    });

    try {
      const tx = await contract.collect(parseEther(amount));
      await tx.wait();

      dispatch(getBalance());
    } catch (e) {
      console.log(e);
    }

    dispatch(setLoaderData({ isLoading: false }));
  };

export const putDollars =
  ({ amount }) =>
  async (dispatch, getState) => {
    dispatch(setLoaderData({ isLoading: true, loadingText: "Putting" }));

    const provider = getState().connect.provider;
    const signer = await provider.getSigner();
    const contract = await getContract({
      name: "Erc20",
      address: USDT_ADDRESS,
      signer,
    });

    try {
      const tx = await contract.transfer(DMD_EXCHANGE_ADDRESS, parseEther(amount));
      await tx.wait();

      dispatch(getBalance());
    } catch (e) {
      console.log(e);
    }

    dispatch(setLoaderData({ isLoading: false }));
  };

export const checkDMDSell = () => async (dispatch, getState) => {
  const provider = getState().connect.provider;
  const contract = await getContract({
    name: "DMDExchange",
    address: DMD_EXCHANGE_ADDRESS,
    provider,
  });

  const isSellDMDEnabled = await contract.sellTokenEnabled();
  dispatch(setSellDMDEnabled(isSellDMDEnabled));
};

export const toggleBnbSell = () => async (dispatch, getState) => {
  dispatch(setLoaderData({ isLoading: true, loadingText: "Wait" }));

  const isSellDMDEnabled = getState().exchanger.isSellDMDEnabled;
  const provider = getState().connect.provider;
  const signer = await provider.getSigner();
  const contract = await getContract({
    name: "DMDExchange",
    address: DMD_EXCHANGE_ADDRESS,
    signer,
  });

  try {
    const tx = await contract.updateSellTokenEnabled(!isSellDMDEnabled);
    await tx.wait();

    dispatch(checkDMDSell());
  } catch (e) {
    console.log(e);
  }

  dispatch(setLoaderData({ isLoading: false }));
};

export const getRates = () => async (dispatch, getState) => {
  const provider = getState().connect.provider;
  const contract = await getContract({
    name: "DMDExchange",
    address: DMD_EXCHANGE_ADDRESS,
    provider,
  });

  const buyRate = Number((await contract.buyRate()) / SWAP_RATE_MULTIPLIER);
  const sellRate = Number((await contract.sellRate()) / SWAP_RATE_MULTIPLIER);

  dispatch(setRates({ buyRate, sellRate }));
};

export const setBuySellRates =
  ({ amount, methodKey }) =>
  async (dispatch, getState) => {
    dispatch(setLoaderData({ isLoading: true, loadingText: "Wait" }));

    const provider = getState().connect.provider;
    const signer = await provider.getSigner();
    const contract = await getContract({
      name: "DMDExchange",
      address: DMD_EXCHANGE_ADDRESS,
      signer,
    });

    try {
      const tx = await contract[methodKey](BigInt(amount) * SWAP_RATE_MULTIPLIER);
      await tx.wait();

      dispatch(getRates());
    } catch (e) {
      console.log(e);
    }

    dispatch(setLoaderData({ isLoading: false }));
  };
