import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMembers } from "../../utils/members";
import MembersList from "./MembersList";
import { getContractPause, setContractPause } from "../../utils/tokenPause";
import Exchanger from "../Exchanger";
import { DMD_EXCHANGE_ADDRESS, DMD_GAME_TRANSFER_ADDRESS } from "../../config/api";
import styles from "./styles.module.scss";
import { getTokenTreasury } from "../../utils/treasury";
import AppTooltip from "../ui/icons/Tooltip";

export const CONTRACTS_BG = {
  [DMD_EXCHANGE_ADDRESS]: "bg--orange accountants-not-delete",
  [DMD_GAME_TRANSFER_ADDRESS]: "bg--green accountants-not-delete",
};

const TOOLTIP_CONTENT = {
  DMDToken: {
    stopBtn:
      "Постановка на паузу блокирует любые переводы DMD, в том числе через метамаск и панкейксвап, но не на централизованных биржах.<br /><br /> На паузу могут ставить только администраторы.",
  },
  DMDExchange: {
    stopBtn:
      "Постановка на паузу блокирует операции покупки и продажи DMD токена в контракте Exchange. Настройки при этом можно менять.<br /><br /> На паузу могут ставить только администраторы.",
  },
  DMDGame: {
    stopBtn:
      "Постановка на паузу блокирует операции Game Transfer Bridge - обмены внутреннего на внешний DMD и на обратно будут невозможны.<br /><br /> На паузу могут ставить только администраторы.",
  },
};

const Members = ({ dmdMembers }) => {
  const dispatch = useDispatch();
  const wallet = useSelector(s => s.connect.wallet);

  const [isPaused, setIsPaused] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { members, title, dmdName, dmdAddress, treasury } = dmdMembers;

  useEffect(() => {
    dispatch(getContractPause({ dmdMembers, setIsPaused }));

    for (const membersKey in members) {
      dispatch(
        getMembers({
          role: members[membersKey].getRoleMethod,
          dmdMembers,
        }),
      );
    }

    if (dmdName === "DMDToken") dispatch(getTokenTreasury({ dmdMembers }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const hasPermission = members.admins.list.some(
      adminWallet => adminWallet.toLowerCase() === wallet.toLowerCase(),
    );
    setHasPermission(hasPermission);

    if (members.admins.list.length) setIsLoading(false);
  }, [members.admins.list, wallet]);

  const pauseHandler = () => {
    dispatch(setContractPause({ dmdMembers, isPaused, setIsPaused }));
  };

  if (isLoading) return null;

  return (
    <div>
      <h1>{title}</h1>
      {!isLoading &&
        (hasPermission ? (
          <>
            <span className={CONTRACTS_BG[dmdAddress] || ""}>{dmdAddress}</span>
            <AppTooltip id="contract-address" content="Адрес контракта" />
            <div style={{ marginTop: "5px" }}>
              <button onClick={pauseHandler} className={isPaused ? "bg--green" : "bg--red"}>
                {isPaused ? "Cancel pause" : "Set on pause"}
              </button>
              <AppTooltip
                id="contract-pause"
                content={TOOLTIP_CONTENT[dmdMembers.dmdName].stopBtn}
              />
            </div>
            {Object.values(members).map(members => (
              <MembersList key={members.title} members={members} dmdMembers={dmdMembers} />
            ))}
            {dmdName === "DMDExchange" && <Exchanger />}
            {dmdName === "DMDToken" && (
              <h2 className={styles.title}>
                Treasury: {treasury}{" "}
                <AppTooltip
                  id="treasury"
                  content="Баланс сокровищницы. Контакты Exchange и Game Transfer Bridge списывают и начисляют в нее DMD."
                />
              </h2>
            )}
          </>
        ) : (
          <p>You dont have permission for that contract</p>
        ))}
    </div>
  );
};

export default Members;
