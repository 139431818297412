import { createSlice } from "@reduxjs/toolkit";
import {
  DMD_EXCHANGE_ADDRESS,
  DMD_GAME_TRANSFER_ADDRESS,
  DMD_TOKEN_ADDRESS,
} from "../../config/api";

const initialState = {
  dmdTokenMembers: {
    title: "DMD Token",
    members: {
      admins: {
        title: "List of administrators:",
        list: [],
        getRoleMethod: "DEFAULT_ADMIN_ROLE",
      },
      accountants: {
        title: "List of accountants:",
        list: [],
        getRoleMethod: "ACCOUNTANT_ROLE",
      },
    },
    dmdName: "DMDToken",
    dmdAddress: DMD_TOKEN_ADDRESS,
    actionKey: "setDmdTokenMembers",
    treasury: 0,
  },

  dmdExchangeMembers: {
    title: "DMD Exchange",
    members: {
      admins: {
        title: "List of administrators:",
        list: [],
        getRoleMethod: "DEFAULT_ADMIN_ROLE",
      },
      collector: {
        title: "List of collectors:",
        list: [],
        getRoleMethod: "COLLECTOR_ROLE",
      },
      managers: {
        title: "List of managers:",
        list: [],
        getRoleMethod: "MANAGER_ROLE",
      },
    },
    dmdName: "DMDExchange",
    dmdAddress: DMD_EXCHANGE_ADDRESS,
    actionKey: "setDmdExchangeMembers",
  },

  dmdGameMembers: {
    title: "DMD Game Transfer Bridge",
    members: {
      admins: {
        title: "List of administrators:",
        list: [],
        getRoleMethod: "DEFAULT_ADMIN_ROLE",
      },
    },
    dmdName: "DMDGame",
    dmdAddress: DMD_GAME_TRANSFER_ADDRESS,
    actionKey: "setDmdGameMembers",
  },
};

export const membersSlice = createSlice({
  name: "members",
  initialState,
  reducers: {
    setDmdTokenMembers: (state, action) => {
      switch (action.payload.role) {
        case "DEFAULT_ADMIN_ROLE":
          state.dmdTokenMembers.members.admins.list = action.payload.list;
          break;
        case "ACCOUNTANT_ROLE":
          state.dmdTokenMembers.members.accountants.list = action.payload.list;
          break;
      }
    },
    setDmdTokenTreasury: (state, action) => {
      state.dmdTokenMembers.treasury = action.payload;
    },
    setDmdExchangeMembers: (state, action) => {
      switch (action.payload.role) {
        case "DEFAULT_ADMIN_ROLE":
          state.dmdExchangeMembers.members.admins.list = action.payload.list;
          break;
        case "COLLECTOR_ROLE":
          state.dmdExchangeMembers.members.collector.list = action.payload.list;
          break;
        case "MANAGER_ROLE":
          state.dmdExchangeMembers.members.managers.list = action.payload.list;
          break;
      }
    },
    setDmdGameMembers: (state, action) => {
      switch (action.payload.role) {
        case "DEFAULT_ADMIN_ROLE":
          state.dmdGameMembers.members.admins.list = action.payload.list;
          break;
      }
    },
  },
});

export const { setDmdTokenMembers, setDmdExchangeMembers, setDmdGameMembers, setDmdTokenTreasury } =
  membersSlice.actions;

export const membersActions = {
  setDmdTokenMembers,
  setDmdExchangeMembers,
  setDmdGameMembers,
};

export default membersSlice.reducer;
