import styles from "./styles.module.scss";

export const TooltipIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="14"
    height="14"
    viewBox="0 0 512 512"
    className={styles.tooltipIconStyles}
  >
    <g>
      <g data-name="Layer 2">
        <circle
          cx="256"
          cy="256"
          r="256"
          fill="#ffffff"
          opacity="1"
          data-original="#ff2147"
        ></circle>
        <g fill="#fff">
          <path
            d="M252.94 395.94h-25.38c-5.74 0-9.58-3.7-9.6-9.42 2.1-65.89-11.87-60.86 35.69-60.59 45.7-.33 32.19-4.8 34.29 59.78 0 6.71-3.51 10.23-10.17 10.24zM251.9 312c-8.28 0-16.56-.06-24.84 0-6.76.43-8.08-7.28-9-12.35-2.35-21.88 6.75-38.87 22.85-52.87 11.3-10.81 26.76-15.33 38.72-25.09 11.14-9.61 10-25.83-1.87-34.37-12.92-9.26-33.48-10.23-46.73-1.66-9.37 6.58-16.28 16.24-23.7 24.87-4.38 5.21-8.71 5.77-14.12 1.67q-17.05-12.92-34-25.93c-4.78-3.66-5.41-7.9-2.29-13 31.3-57.2 110.88-75.8 163.08-36 35 22.77 50.45 73.95 23.25 108.46-10.63 15-28.66 21.56-42.77 32.52C280.56 295 293 310.76 276.75 312H251.9z"
            fill="#000000"
            opacity="1"
            data-original="#ffffff"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
