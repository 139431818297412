import Loader from "../Loader/Loader";
import { useSelector } from "react-redux";

const GlobalLoader = () => {
  const { isLoading, loadingText } = useSelector(s => s.main.loader);

  if (!isLoading) return null;

  return <Loader statusText={loadingText} />;
};

export default GlobalLoader;
