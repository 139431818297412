import { useDispatch } from "react-redux";
import * as yup from "yup";
import { useFormContext } from "react-hook-form";
import { setBuySellRates } from "../../../../utils/exchanger";
import styles from "../styles.module.scss";
import Button from "../../../ui/Button";
import Input from "../../../ui/Input/Input";
import AppTooltip from "../../../ui/icons/Tooltip";

export const buyRateSchema = yup
  .object({
    buyRateAmount: yup
      .number()
      .typeError("Must be a valid a number")
      .required("Buy rate amount is required")
      .positive("Amount must be positive"),
  })
  .required();

const BuyRate = () => {
  const dispatch = useDispatch();

  const {
    formState: { errors },
    handleSubmit,
    reset,
  } = useFormContext();

  const buyRateHandler = async data => {
    await dispatch(setBuySellRates({ amount: data.buyRateAmount, methodKey: "updateBuyRate" }));

    reset();
  };

  return (
    <form onSubmit={handleSubmit(buyRateHandler)}>
      <div className={styles.wrapper}>
        <span>
          Set buy rate{" "}
          <AppTooltip id="set-buy-rate" content="Это поле позволяет установить курс покупки." />
        </span>{" "}
        <Input name="buyRateAmount" />
        <Button type="submit" content="Set" className={styles.button} />
      </div>
      {errors.buyRateAmount && <p className={styles.error}>{errors.buyRateAmount.message}</p>}
    </form>
  );
};

export default BuyRate;
