import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

const FormContainer = ({ validationSchema, children, context }) => {
  const methods = useForm({
    defaultValues: {},
    resolver: yupResolver(validationSchema),
    context,
    mode: "all",
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default FormContainer;
