import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  privateBalance: 0,
  exchangerBalance: 0,
  buyRate: 0,
  sellRate: 0,
  isSellDMDEnabled: true,
};

const exchangerSlice = createSlice({
  name: "exchanger",
  initialState,
  reducers: {
    setRates: (state, action) => {
      state.buyRate = action.payload.buyRate;
      state.sellRate = action.payload.sellRate;
    },
    setPrivateBalance: (state, action) => {
      state.privateBalance = action.payload;
    },
    setExchangerBalance: (state, action) => {
      state.exchangerBalance = action.payload;
    },
    setSellDMDEnabled: (state, action) => {
      state.isSellDMDEnabled = action.payload;
    },
  },
});

export const { setRates, setPrivateBalance, setExchangerBalance, setSellDMDEnabled } =
  exchangerSlice.actions;

export default exchangerSlice.reducer;
