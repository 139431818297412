import IERC20 from "./IERC20.json";
import DMDToken from "./DMDToken.json";
import DMDExchange from "./DMDExchange.json";
import DMDGame from "./DMDGameTransferBridge.json";

export const abiStorage = {
  Erc20: IERC20,
  DMDExchange,
  DMDToken,
  DMDGame,
};
