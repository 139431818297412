import { getContract } from "./contract";
import { setLoaderData } from "../store/reducers/mainSlice";

export const getContractPause =
  ({ dmdMembers, setIsPaused }) =>
  async (dispatch, getState) => {
    const provider = getState().connect.provider;
    const contract = await getContract({
      name: dmdMembers.dmdName,
      address: dmdMembers.dmdAddress,
      provider,
    });

    const isPaused = await contract.paused();

    setIsPaused(isPaused);
  };

export const setContractPause =
  ({ dmdMembers, isPaused, setIsPaused }) =>
  async (dispatch, getState) => {
    dispatch(setLoaderData({ isLoading: true, loadingText: "Wait" }));

    const provider = getState().connect.provider;
    const signer = await provider.getSigner();
    const contract = await getContract({
      name: dmdMembers.dmdName,
      address: dmdMembers.dmdAddress,
      signer,
    });

    try {
      const tx = isPaused ? await contract.unpause() : await contract.pause();
      await tx.wait();
      await dispatch(getContractPause({ dmdMembers, setIsPaused }));
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setLoaderData({ isLoading: false }));
    }
  };
