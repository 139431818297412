import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { handleConnect, handleDisconnect } from "../../utils/connect";
import GlobalLoader from "../../components/GlobalLoader/GlobalLoader";
import Button from "../../components/ui/Button";
import Members from "../../components/Members";
import { ENV } from "../../config/api";

const Homepage = () => {
  const dispatch = useDispatch();
  const wallet = useSelector(s => s.connect.wallet);
  const isWalletConnected = !!wallet;

  const connect = async () => {
    try {
      await dispatch(handleConnect());
    } catch {}
  };

  const disconnect = async () => {
    await dispatch(handleDisconnect());
  };

  const dmdTokenMembers = useSelector(s => s.members.dmdTokenMembers);
  const dmdExchangeMembers = useSelector(s => s.members.dmdExchangeMembers);
  const dmdGameMembers = useSelector(s => s.members.dmdGameMembers);

  return (
    <div className={styles.pageWrapper}>
      {isWalletConnected ? (
        <>
          <div className={styles.membersWrapper}>
            <Members dmdMembers={dmdTokenMembers} />
            <Members dmdMembers={dmdExchangeMembers} />
            <Members dmdMembers={dmdGameMembers} />
          </div>
          {ENV === "dev" && <p className={styles.developmentMode}>development</p>}
          <Button content="Logout" onClick={disconnect} className={styles.logoutButton} />
        </>
      ) : (
        <Button content="Connect" onClick={connect} />
      )}
      <GlobalLoader />
    </div>
  );
};

export default Homepage;
