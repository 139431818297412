import { createSlice } from "@reduxjs/toolkit";
import { NAVIGATION_STEPS } from "../../config";

const initialState = {
  isMobile: false,
  queryToken: "",
  loader: {
    isLoading: false,
    loadingText: "Handling auth data",
  },
  mainError: {
    message: "",
  },
  navigationStep: NAVIGATION_STEPS.connect,
};

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setMobile: (state, action) => {
      state.isMobile = action.payload;
    },
    setQueryToken: (state, action) => {
      state.queryToken = action.payload;
    },
    setLoaderData: (state, action) => {
      state.loader.isLoading = action.payload.isLoading;
      state.loader.loadingText = action.payload.loadingText || "";
    },
    setMainError: (state, action) => {
      state.mainError.message = action.payload.message;
    },
    setNavigationStep: (state, action) => {
      state.navigationStep = action.payload;
    },
  },
});

export const { setMobile, setQueryToken, setLoaderData, setMainError, setNavigationStep } =
  mainSlice.actions;

export default mainSlice.reducer;
