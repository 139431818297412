import { collectDollars } from "../../../../utils/exchanger";
import { useFormContext } from "react-hook-form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import styles from "../styles.module.scss";
import Button from "../../../ui/Button";
import Input from "../../../ui/Input/Input";
import AppTooltip from "../../../ui/icons/Tooltip";

export const collectSchema = yup
  .object({
    collectDollarsAmount: yup
      .number()
      .typeError("Must be a valid a number")
      .required("Collect amount is required")
      .positive("Amount must be positive")
      .test("NotCorrectAmount", (value, { options, createError }) => {
        if (value > options.context.exchangerBalance) {
          return createError({
            message: `The amount must not exceed the amount of the exchanger`,
          });
        }

        return true;
      }),
  })
  .required();

const CollectDollars = () => {
  const dispatch = useDispatch();

  const {
    formState: { errors },
    handleSubmit,
    reset,
  } = useFormContext();

  const collectDollarsHandler = async data => {
    await dispatch(
      collectDollars({
        amount: String(data.collectDollarsAmount),
      }),
    );

    reset();
  };

  return (
    <form onSubmit={handleSubmit(collectDollarsHandler)}>
      <div className={styles.wrapper}>
        <span>
          Collect USDT
          <AppTooltip
            id="collect-USDT"
            content="Это поле позволяет вывести USDT с баланса контракта Exchange. <br /> <br />USDT будут выведены на адрес кошелька текущего подключенного пользователя.<br /> <br />Необходимо учитывать, что если вывести всё, то пользователи не смогут обменнять DMD на USDT."
          />
        </span>{" "}
        <Input name="collectDollarsAmount" />
        <Button type="submit" content="Collect" className={styles.button} />
      </div>
      {errors.collectDollarsAmount && (
        <p className={styles.error}>{errors.collectDollarsAmount.message}</p>
      )}
    </form>
  );
};

export default CollectDollars;
