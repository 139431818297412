import { NETWORK_PARAMS } from "../config";

export const checkIfMetamaskInstalled = () => !!window.ethereum;

export const checkIfCorrectNetwork = async provider => {
  try {
    const userChainId = await provider.send("eth_chainId");

    return userChainId === `0x${Number(NETWORK_PARAMS.chainId).toString(16)}`;
  } catch (e) {
    return false;
  }
};

export const switchEthereumNetwork = async provider => {
  try {
    // check if the chain to connect to is installed
    await provider.send("wallet_switchEthereumChain", [
      {
        chainId: NETWORK_PARAMS.chainId,
      },
    ]);
  } catch (error) {
    // This error code indicates that the chain has not been added to MetaMask
    if (error.error?.code === 4902) {
      try {
        await provider.send("wallet_addEthereumChain", [NETWORK_PARAMS]);
      } catch (addError) {
        console.error(addError);

        throw addError;
      }
    }

    throw error;
  }
};
