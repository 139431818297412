import { putDollars } from "../../../../utils/exchanger";
import { useFormContext } from "react-hook-form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import styles from "../styles.module.scss";
import Button from "../../../ui/Button";
import Input from "../../../ui/Input/Input";
import AppTooltip from "../../../ui/icons/Tooltip";

export const putSchema = yup
  .object({
    putDollarsAmount: yup
      .number()
      .typeError("Must be a valid a number")
      .required("Deposit amount is required")
      .positive("Amount must be positive")
      .test("NotCorrectAmount", (value, { options, createError }) => {
        if (value > options.context.privateBalance) {
          return createError({
            message: `The amount should not exceed your personal`,
          });
        }

        return true;
      }),
  })
  .required();

const PutDollars = () => {
  const dispatch = useDispatch();

  const {
    formState: { errors },
    handleSubmit,
    reset,
  } = useFormContext();

  const putDollarsHandler = async data => {
    await dispatch(
      putDollars({
        amount: String(data.putDollarsAmount),
      }),
    );
    reset();
  };

  return (
    <form onSubmit={handleSubmit(putDollarsHandler)}>
      <div className={styles.wrapper}>
        <span>
          Put USDT
          <AppTooltip
            id="put-USDT"
            content="Это поле позволяет зачислить USDT в контракт Exchange с адреса кошелька текущего подключенного пользователя."
          />
        </span>{" "}
        <Input name="putDollarsAmount" />
        <Button type="submit" content="Put" className={styles.button} />
      </div>
      {errors.putDollarsAmount && <p className={styles.error}>{errors.putDollarsAmount.message}</p>}
    </form>
  );
};

export default PutDollars;
