import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  historyData: [],
};

export const historySlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setHistoryData: (state, action) => {
      state.historyData = action.payload;
    },
  },
});

export const { setHistoryData } = historySlice.actions;

export default historySlice.reducer;
