import styles from "./styles.module.scss";

const Loader = ({ statusText }) => (
  <div className={styles.loaderWrapper}>
    {statusText && <p className={styles.statusText}>{statusText}...</p>}
    <span className={styles.loader} />
  </div>
);

export default Loader;
