import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBalance, checkDMDSell, toggleBnbSell, getRates } from "../../utils/exchanger";
import CollectDollars, { collectSchema } from "./forms/CollectDollars";
import PutDollars, { putSchema } from "./forms/PutDollars";
import BuyRate, { buyRateSchema } from "./forms/BuyRate";
import SellRate, { sellRateSchema } from "./forms/SellRate";
import styles from "./styles.module.scss";
import Button from "../ui/Button";
import FormContainer from "../FormContainer";
import AppTooltip from "../ui/icons/Tooltip";

const Exchanger = () => {
  const dispatch = useDispatch();
  const { buyRate, sellRate, privateBalance, exchangerBalance, isSellDMDEnabled } = useSelector(
    s => s.exchanger,
  );
  const collectorsList = useSelector(s => s.members.dmdExchangeMembers.members.collector.list);
  const managersList = useSelector(s => s.members.dmdExchangeMembers.members.managers.list);
  const wallet = useSelector(s => s.connect.wallet);

  useEffect(() => {
    dispatch(getBalance());
    dispatch(checkDMDSell());
    dispatch(getRates());
  }, [dispatch]);

  const sellBnbHandler = () => {
    dispatch(toggleBnbSell());
  };

  const isCollector = collectorsList.some(
    collector => collector.toLowerCase() === wallet.toLowerCase(),
  );

  const isManager = managersList.some(manager => manager.toLowerCase() === wallet.toLowerCase());

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>Exchange Settings</h2>
      <div className={styles.content}>
        <div className={styles.subtitle}>USDT balance: </div>
        <div className={styles.content}>
          <div className={styles.output}>
            Your balance: {privateBalance} USDT
            <AppTooltip
              id="your-balance"
              content="Это баланс USDT на кошельке текущего подключенного пользователя, а не баланс контракта Exchange."
            />
          </div>
          <div className={styles.output}>
            Exchange balance: {exchangerBalance} USDT
            <AppTooltip
              id="exchange-balance"
              content="Это баланс USDT контракта Exchange, т.е. деньги, которые можно снять."
            />
          </div>
          {isCollector && (
            <FormContainer validationSchema={collectSchema} context={{ exchangerBalance }}>
              <CollectDollars />
            </FormContainer>
          )}
          <FormContainer validationSchema={putSchema} context={{ privateBalance }}>
            <PutDollars />
          </FormContainer>
        </div>

        <div className={styles.subtitle}>Rates:</div>
        <div className={styles.content}>
          {isManager && (
            <>
              <Button
                onClick={sellBnbHandler}
                content={isSellDMDEnabled ? "Disable DMD sell" : "Enable DMD sell"}
                className={isSellDMDEnabled ? "bg--red" : "bg--green"}
              />
              <AppTooltip
                id="sell-button"
                content="Эта кнопка блокирует обмен DMD -> USDT. Таким образом, контракт Exchange будет работать только в одном направлении и кнопка Sell, которая находится в интерфейсе обмена, будет недоступна."
              />
            </>
          )}
          <div className={styles.output}>
            Buy rate: {buyRate}
            <AppTooltip
              id="buy-rate"
              content="Курс покупки. <br /> <br />Показывает, какое количество DMD получит пользователь, если продаст один USDT."
            />
          </div>
          <div className={styles.output}>
            Sell rate: {sellRate}
            <AppTooltip
              id="sell-rate"
              content="Курс продажи. <br /> <br />Показывает, какое количество DMD должен продать пользователь, чтобы получить один USDT."
            />
          </div>
          {isManager && (
            <>
              <FormContainer validationSchema={buyRateSchema}>
                <BuyRate />
              </FormContainer>
              <FormContainer validationSchema={sellRateSchema}>
                <SellRate />
              </FormContainer>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Exchanger;
