export const ENV = process.env.ENV;
export const CHAIN_ID = process.env.CHAIN_ID;
export const RPC_URL = process.env.RPC_URL;
export const CHAIN_NAME = process.env.CHAIN_NAME;
export const NATIVE_CURRENCY_NAME = process.env.NATIVE_CURRENCY_NAME;
export const NATIVE_CURRENCY_SYMBOL = process.env.NATIVE_CURRENCY_SYMBOL;
export const BLOCK_EXPLORER_URL = process.env.BLOCK_EXPLORER_URL;
export const API_URL = process.env.API_URL;
export const DMD_TOKEN_ADDRESS = process.env.DMD_TOKEN_ADDRESS;
export const DMD_EXCHANGE_ADDRESS = process.env.DMD_EXCHANGE_ADDRESS;
export const DMD_GAME_TRANSFER_ADDRESS = process.env.DMD_GAME_TRANSFER_ADDRESS;
export const USDT_ADDRESS = process.env.USDT_ADDRESS;

export const API_ROUTES = {
  auth: "/auth/",
  connectWallet: "/connect_wallet/",
};
